
































import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

@Component({
  metaInfo: {
    title: "Mesafeli Satış Sözleşmesi"
  },
  components: {
    Breadcrumbs
  }
})
export default class TermsOfUse extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Mesafeli Satış Sözleşmesi"
    }
  ];
}
